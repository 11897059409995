import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import styles from '../styles/pages/UserPayment.module.css';
import { UserContext } from '../components/contexts/UserContext';

const UserPayment = () => {
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [error, setError] = useState('');
  const [paymentData, setPaymentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [timeLeft, setTimeLeft] = useState(600); // 10 minutos em segundos
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate();
  const { logoUrl, forceUpdateUser } = useContext(UserContext) || {};

  useEffect(() => {
    if (isAuthenticated) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isAuthenticated]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const sessionToken = localStorage.getItem('sessionToken');
    const isAuthenticated = !!token && !!sessionToken;

    setIsAuthenticated(isAuthenticated);

    const fetchPaymentSettings = async () => {
      if (!isAuthenticated) return;

      try {
        const res = await axios.get('/api/admin/public/payment-settings', {
          headers: {
            Authorization: `Bearer ${token}`,
            'session-token': sessionToken,
          },
        });

        setPaymentAmount(res.data.paymentAmount);
      } catch (err) {
        console.error('Erro ao obter configurações financeiras:', err);

        if (err.response?.status === 401 || err.response?.status === 403) {
          navigate('/pagamento');
        } else {
          setError('Erro ao obter configurações financeiras.');
        }
      }
    };

    fetchPaymentSettings();
  }, [navigate]);

  const handlePayment = async () => {
    setLoading(true);
    setError('');
    const token = localStorage.getItem('token');
    const sessionToken = localStorage.getItem('sessionToken');

    try {
      const response = await axios.post(
        '/api/admin/payment',
        { amount: paymentAmount },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'session-token': sessionToken,
          },
        }
      );

      if (response.data.success) {
        setPaymentData(response.data.paymentData);
        setModalIsOpen(true);

        const pollingInterval = setInterval(async () => {
          try {
            const statusResponse = await axios.get(
              `/api/admin/payment-status/${response.data.paymentData.txid}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'session-token': sessionToken,
                },
              }
            );

            if (statusResponse.data.status === 'PAID') {
              clearInterval(pollingInterval);

              if (response.data.token) {
                localStorage.setItem('token', response.data.token);
                await forceUpdateUser();
              }

              setModalIsOpen(false);
              navigate('/dashboard');
            }
          } catch (err) {
            console.error('Erro ao verificar status do pagamento:', err);
          }
        }, 5000);

        setTimeout(() => clearInterval(pollingInterval), 600000);
      }
    } catch (err) {
      console.error('Erro ao criar pagamento:', err);
      setError('Erro ao criar pagamento. Verifique as configurações.');
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text)
        .then(() => {
          alert('Código PIX copiado com sucesso!');
        })
        .catch((err) => {
          console.error('Erro ao copiar código PIX:', err);
        });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        alert('Código PIX copiado com sucesso!');
      } catch (err) {
        console.error('Erro ao copiar código PIX:', err);
      }
      document.body.removeChild(textArea);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setPaymentData(null);
  };

  return (
    <div className={styles['payment-container']}>
      {isAuthenticated && (
        <div className={styles['timer']}>
          OFERTA EXPIRA EM: {formatTime(timeLeft)}
        </div>
      )}

      {isAuthenticated ? (
        <div className={styles['payment-card']}>
          <div className={styles['logo-container']}>
            <img src={logoUrl} alt="Logo" className={styles['logo']} />
          </div>

          <h2>Bem Vindo!</h2>
          <p className={styles['marketing-text']}>
            Você Tentou Acessar uma Página Exclusiva para Assinantes!
          </p>

          {error && <p className={styles['error-message']}>{error}</p>}

          <p className={styles['original-price']}>US$ 599,00</p>
          <p className={styles['discounted-price']}>Por apenas US$ 29,99</p>

          <button onClick={handlePayment} className={styles['payment-button']} disabled={loading}>
            {loading ? 'Processando...' : 'Obter Acesso'}
          </button>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Detalhes do Pagamento"
            overlayClassName={styles['modal-overlay']}
            className={styles['modal-content']}
          >
            <div className={styles['modal-header']}>
              <h2>Detalhes do Pagamento</h2>
            </div>
            {paymentData && (
              <div className={styles['payment-details']}>
                <p><strong>Status:</strong> {paymentData.status}</p>
                {paymentData.qrcodePayload && (
                  <div className={styles['qrcode-container']}>
                    <p><strong>Escaneie o QR Code:</strong></p>
                    <img
                      src={`data:image/png;base64,${paymentData.qrcodePayload}`}
                      alt="QR Code do Pagamento"
                      className={styles['qrcode-image']}
                    />
                  </div>
                )}
                {paymentData.copypasteCode && (
                  <div className={styles['copypaste-container']}>
                    <p><strong>Código de Copiar e Colar:</strong></p>
                    <div className={styles['copypaste-wrapper']}>
                      <span>{paymentData.copypasteCode}</span>
                      <button onClick={() => copyToClipboard(paymentData.copypasteCode)} className={styles['copy-button']}>
                        Copiar
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Modal>
        </div>
      ) : (
        <div className={styles['marketing-card']}>
          <div className={styles['logo-container']}>
            <img src={logoUrl} alt="Logo" className={styles['logo']} />
          </div>
          <h2>Venha Fazer Parte!</h2>
          <p className={styles['marketing-message']}>Toda grande jornada começa com um primeiro passo.</p>
          <div className={styles['action-buttons']}>
            <button onClick={() => navigate('/register')} className={styles['register-button']}>Cadastre-se</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserPayment;
