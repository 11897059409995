import React from 'react';
import Footer from './Footer';
import styles from '../styles/pages/Home.module.css';
import tempoImage from '../assets/econimia-tempo.png';
import ferramentaImage from '../assets/ferramentas.png';
import artificialImage from '../assets/inteligencia-artificial.png';
import telegramImage from '../assets/telegram-2.png';
import instagramImage from '../assets/instagram.png';



const Home = () => {
  return (
    <div className={styles['home-container']}>
      {/* Hero Section */}
      <section className={styles['hero-section']}>
        <div className={styles['hero-content']}>
          {/*<h1>StatsFut</h1>
          <h2>Estatísticas 100% Selecionadas</h2>
          <p>
            Alta taxa de acerto. Ganhe mais com nossas Estatísticas todos os dias. 
            Dados prontos para você apostar e começar a lucrar.
          </p> */}
        </div>
      </section>

      {/* Destaques */}
      <section className={styles['highlights-section']}>
        <h2>  </h2>
        <div className={styles['highlights-container']}>
          <div className={styles['highlight-item']}>
            <img src={artificialImage} alt="Alta Taxa de Acerto" />
            <h3>Alta Taxa de Acerto</h3>
            <p>
            Através de algoritmos avançados e exclusivos, oferecemos uma gama de informações objetivas, transformando a coleta e  análise de dados em um processo ágil e informativo.
            </p>
          </div>
          <div className={styles['highlight-item']}>
            <img src={ferramentaImage} alt="Dados 100% Selecionados" />
            <h3>Dados 100% Selecionados</h3>
            <p>
            Elevamos os apostadores a um novo patamar de profissionalismo e precisão, o sistema se tornou um diferencial crucial para quem busca não apenas participar, mas dominar no campo das apostas esportivas.
            </p>
          </div>
          <div className={styles['highlight-item']}>
            <img src={tempoImage} alt="Economia de Tempo" />
            <h3>Economia de Tempo</h3>
            <p>
            Análises detalhadas para que você tome decisões rapidas e assertivas nas suas apostas.
            </p>
          </div>
        </div>
      </section>

     {/* Escassez 
      <section className={styles['scarcity-section']}>
        <h2>Vagas Limitadas</h2>
        <p>
          Garanta sua vaga agora mesmo. Não perca a chance de começar a lucrar hoje.
        </p>
        <button className={styles['cta-button']}>Adquirir Acesso Agora</button>
        <div className={styles['availability']}>
          <span>Restam <strong>Poucas</strong> vagas disponíveis!</span>
        </div>
      </section>

      {/* Depoimentos 
      <section className={styles['testimonials-section']}>
        <h2>O que nossos usuários dizem</h2>
        <div className={styles['testimonials-container']}>
          <div className={styles['testimonial-item']}>
            <p>
              "Comecei a usar o StatsFut há um mês e já consegui ótimos resultados.
              Recomendo muito!"
            </p>
            <h3>João Silva</h3>
          </div>
          <div className={styles['testimonial-item']}>
            <p>
              "Ótimo sistema, fácil de usar e com palpites super precisos. Vale a pena!"
            </p>
            <h3>Maria Oliveira</h3>
          </div>
        </div>
      </section>*/}

      {/* Redes Sociais */}
      <section className={styles['social-section']}>
        <h2>Siga-nos nas redes sociais</h2>
        <p>Fique por dentro das novidades e ofertas exclusivas.</p>
        <div className={styles['social-icons']}>
          <a href="https://instagram.com">
            <img src={instagramImage} alt="Instagram" />
          </a>
          <a href="https://t.me">
            <img src={telegramImage} alt="Telegram" />
          </a>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;