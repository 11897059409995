import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../styles/pages/AdminPaymentSettings.module.css';

const AdminPaymentSettings = () => {
  const [settings, setSettings] = useState({
    paymentAmount: '',
    xgate: {
      email: '',
      password: '',
      xgateEndpoint: '',
    },
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const res = await axios.get('/api/admin/payment-settings', {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });
        setSettings(res.data);
      } catch (err) {
        console.error('Erro ao obter configurações financeiras:', err);
        setError('Erro ao obter configurações financeiras.');
      } finally {
        setLoading(false);
      }
    };
    fetchSettings();
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith('xgate.')) {
      const field = name.split('.')[1];
      setSettings((prevSettings) => ({
        ...prevSettings,
        xgate: {
          ...prevSettings.xgate,
          [field]: value,
        },
      }));
    } else {
      setSettings((prevSettings) => ({
        ...prevSettings,
        [name]: value,
      }));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/admin/payment-settings', settings, {
        headers: { 'x-auth-token': localStorage.getItem('token') },
      });
      alert('Configurações salvas com sucesso!');
    } catch (err) {
      console.error('Erro ao salvar configurações financeiras', err);
      setError('Erro ao salvar configurações financeiras.');
    }
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div className={styles['error-message']}>{error}</div>;
  }

  return (
    <form onSubmit={onSubmit} className={styles['form-container']}>
      <h2 className={styles['form-title']}>Configurações Financeiras</h2>

      <div className={styles['settings-group']}>
        <label className={styles['form-label']}>Valor do Pagamento (amount):</label>
        <input
          type="number"
          name="paymentAmount"
          value={settings?.paymentAmount || ''}
          onChange={onChange}
          required
          className={styles['form-input']}
        />
      </div>

      <h3 className={styles['section-title']}>XGate</h3>
      <div className={styles['settings-group']}>
        <label className={styles['form-label']}>E-mail:</label>
        <input
          type="text"
          name="xgate.email"
          value={settings?.xgate?.email || ''}
          onChange={onChange}
          placeholder="E-mail XGate"
          className={styles['form-input']}
        />
      </div>

      <div className={styles['settings-group']}>
        <label className={styles['form-label']}>Senha:</label>
        <input
          type="password"
          name="xgate.password"
          value={settings?.xgate?.password || ''}
          onChange={onChange}
          placeholder="Senha XGate"
          className={styles['form-input']}
        />
      </div>

      <div className={styles['settings-group']}>
        <label className={styles['form-label']}>Endpoint:</label>
        <input
          type="text"
          name="xgate.xgateEndpoint"
          value={settings?.xgate?.xgateEndpoint || ''}
          onChange={onChange}
          placeholder="Endpoint XGate"
          className={styles['form-input']}
        />
      </div>

      <button type="submit" className={styles['submit-button']}>
        Salvar Configurações
      </button>
    </form>
  );
};

export default AdminPaymentSettings;
